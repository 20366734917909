module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"F2ypUeeoDinEBqg0xhdCZ6pn8YtbL4db","devKey":"F2ypUeeoDinEBqg0xhdCZ6pn8YtbL4db","trackPage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142317764-2","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"cookieDomain":"app.coinmiles.io","exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"coinmiles-webapp"}},"routeChangeEventName":"coinmiles-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coinmiles","short_name":"Coinmiles","description":"The ultimate reward app. Automatically receive Bitcoin when you shop online. Easier & more rewarding than points.","start_url":"/","background_color":"#FFFFFF","theme_color":"#FF3451","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc71bdb4a55b906883b0d5cdf200c26c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7308aacff99649d9915d146ee2d509db@o183992.ingest.sentry.io/5468415"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
